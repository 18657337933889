import React, { Component } from 'react';
import { ILocation, IPosition } from '../../@types/types';
import { getDistanceFromLatLonInKm as distanceToPortal, getDistanceFromLatLonInKm } from '../../service';
import Detail from '../Detail';

import Map from '../Map';

interface IHomeProps {
  installations: ILocation[]
  mapkey: string
  zoom: number
  title: string
}

interface IHomeState {
  position: IPosition
  sortedInstallations: ILocation[]
  atInstallation: boolean
  selectedInstallation: ILocation | undefined
  showAllInstallationDetails: boolean
  hasGeolocation: boolean
};

class Home extends Component<IHomeProps, IHomeState> {
  state = {
    sortedInstallations: this.props.installations,
    //default close to Madison Square --> 40.74284326914396, -73.98645744427084
    position:  this.props.installations[0].location,
    //{latitude: 40.74284326914396, longitude: -73.98645744427084},
    atInstallation: false,
    selectedInstallation: undefined,
    showAllInstallationDetails: false,
    hasGeolocation: false
  }

  wid: number = -1

  distanceFromClosestPortal: (location: IPosition, installations: ILocation[]) => ILocation[] = (location, installations) => installations.map(portal=>{
          const distance = distanceToPortal(location.latitude,
            location.longitude,
            portal.location.latitude,
            portal.location.longitude)
          return {...portal, ...{distance}}
        }).sort((a,b)=>a.distance > b.distance ? 1 : -1);

  midPoint: (a: IPosition, b: IPosition) => IPosition = (a,b)=>{
    return {
      latitude: (a.latitude + b.latitude) / 2,
      longitude: (a.longitude + b.longitude) / 2,
    }
  }

  processLocationUpdate = (position: GeolocationPosition) => {

    const {installations} = this.props;
    console.log(position);
    const newPosition: IPosition = getDistanceFromLatLonInKm(position.coords.latitude, position.coords.longitude, this.state.position.latitude, this.state.position.longitude) > 0.2 || this.state.selectedInstallation ?
      position.coords :
      this.state.position;

    this.setState({
      position: newPosition,
      sortedInstallations: this.distanceFromClosestPortal(position.coords, installations)
    })
  }

  processLocationError = (err: GeolocationPositionError) => {
    console.log('LocationError ',err)
    this.setState({
      hasGeolocation: false,
    })
  }

  componentDidMount() {
    if("geolocation" in navigator) {
      this.setState({hasGeolocation: true})
      navigator.geolocation.getCurrentPosition(this.processLocationUpdate, this.processLocationError, {enableHighAccuracy: true, })
      // this.wid = navigator.geolocation.watchPosition(this.processLocationUpdate, this.processLocationError, {enableHighAccuracy: true, })
    }
  }

  componentWillUnmount() {
    if(this.state.hasGeolocation && this.wid > -1) navigator.geolocation.clearWatch(this.wid)
  }

  onMarkerClick = (ev: EventTarget | string) => {

    const [selectedInstallation] = this.state.sortedInstallations.filter(v => v.name === ev)
    this.setState({
      selectedInstallation
    })
  }

  render() {
    let {mapkey, zoom, title} = this.props
    let {position, sortedInstallations, selectedInstallation, hasGeolocation} = this.state;
    //@ts-ignore
    let center: IPosition = selectedInstallation?.location || position
    return (

        <div style={{height: '86vh', display: 'flex', flexDirection: 'column'}}>
          <Map
            installations={sortedInstallations}
            mapkey={mapkey}
            zoom={zoom}
            onMarkerClick={this.onMarkerClick}
            // onMapClick={this.onMapClick}
            center={
              //@ts-ignore
              {lat: center.latitude, lng: center.longitude}
            }
            currentPosition={position}
          />
          {selectedInstallation &&
              <Detail
                onClose={()=>this.setState({selectedInstallation: undefined})}
                installation={selectedInstallation!}
                position={position}
               />
          }
        </div>
    );
  }

}

export default Home;
