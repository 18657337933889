import React from 'react';
import './App.css';

import {Route, Link, Switch} from 'react-router-dom';

import {getPortals} from './service';
import {Home, LandingPage, About} from './components';
import { ILocation } from './@types/types';
import Images from './res';

const portals: ILocation[] = getPortals();

function Head(){
  const title = 'Artmap';
  return(<h1 style={{margin: '14px 0'}}>
    <Link to='/go02'>{title}</Link>
    <span style={{float: 'right'}}>

    </span>
  </h1>)
}

function App() {
  return (<div className="App">
      <div style={{height: '98vh'}}>

        <Switch>
          <Route path='/' exact>
            <Head />
            <Home
              installations={portals}
              mapkey={'AIzaSyADFAs-9MakSokkObAKNzkzuwqdMWVm-jg'}
              zoom={13}
              title={'Artmap'}
              />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
