import React, {Component} from 'react'
import './Map.css';
import GoogleMapReact from 'google-map-react';
import { ILocation, IPosition } from '../../@types/types';
import mapStyles from './gray.layout';


const styles = {
  width: '100%',
  height: '100%'
};

const createMapOptions = (maps: GoogleMapReact.Maps) =>{
  return{
    styles: mapStyles,
    fullscreenControl: false
  }
}

interface IMapProps {
  center: GoogleMapReact.Coords,
  zoom: number,
  mapkey: string,
  installations: ILocation[],
  onMarkerClick: (text:string | EventTarget) => void,
  currentPosition: IPosition
};

const Marker: React.FC<{
  text: string,
  imgsrc: string,
  lat: number,
  lng: number,
  onClick: (ev:EventTarget | string)=>void
}> = ({ text, imgsrc, onClick }) => (
  <div className="Marker" onClick={(ev)=>{ev.stopPropagation(); onClick(text);}}>
    <img width={'32px'} height={'auto'} src={imgsrc} alt={text}/>
  </div>
)

const MyLocation: React.FC<{
  lat: number,
  lng: number,
  text: string,
  distance: number
}> = ({text, distance}) => (
  <div className="MyLocation"></div>
)

export default class Map extends Component<IMapProps> {

  render() {
    const {mapkey, center, currentPosition, zoom, installations, onMarkerClick} = this.props;
    return (
		<div style={styles} className='Map' onClick={(ev)=>onMarkerClick('')}>
    <GoogleMapReact
          bootstrapURLKeys={{ key: mapkey }}
          center={center}
          defaultZoom={zoom}
          options={createMapOptions}
        >
        {
          installations[0].distance && <MyLocation
            text={installations[0].name}
            distance={installations[0].distance}
            lat={currentPosition.latitude}
            lng={currentPosition.longitude}
            />
        }
        {
          //@ts-ignore
          installations.map( (installation, i) => <Marker
            key={i}
            onClick={onMarkerClick}
            text={installation.name}
            imgsrc={require(`./markers/cube.png`).default}
            lat={installation.location.latitude}
            lng={installation.location.longitude}

            />)
        }

        </GoogleMapReact>
		</div>
	)
};
}
