const InstallationImages: {[key: string]: any} = {
  cube: require('./cube.jpg').default,
  dodecahedron: require('./dodecahedron.jpg').default,
  icosahedron: require('./icosahedron.jpg').default,
  tetrahedron: require('./tetrahedron.jpg').default,
  octahedron: require('./octahedron.jpg').default,
  landing: require('./landing.png').default
}

export default InstallationImages;
