const Installations: {[key: string]: any} = {
  ios: {
    flame: require('./flame.usdz').default,
    hands1: require('./hands1.usdz').default,
    sphere2: require('./sphere2.usdz').default,
    water: require('./water.usdz').default,
    shell: require('./shell.usdz').default,
    "3 fbx": require('./3 fbx.usdz').default
  },
  andr: {
    flame: require('./flame.glb').default,
    hands1: require('./hands1.glb').default,
    sphere2: require('./sphere2.glb').default,
    water: require('./water.glb').default,
    shell: require('./shell.glb').default,
    "3 fbx": require('./3 fbx.glb').default
  }
}

export default Installations;
